<template>
  <div class="w-full">
    <div class="flex justify-between">
      <select v-model="input.day"
              class="py-1.5 px-2 bg-transparent border-b-2 text-black border-gray-600 text-center">
        <option disabled value="" selected>{{ $t("input_date.day") }}</option>
        <option v-for="day in days" :key="day" :value="day < 10 ? '0' + day : day">{{
            day < 10 ? '0' + day : day
          }}
        </option>
      </select>
      <select @change="updateDays" v-model="input.month"
              class="py-1.5 px-2 bg-transparent border-b-2 text-black border-gray-600 text-center">
        <option disabled value="" selected>{{ $t("input_date.month") }}</option>
        <option v-for="(month, index) in months" v-bind:key="month"
                :value="index < 9 ? '0' + (index + 1) : (index + 1)">{{ $t(month) }}
        </option>
      </select>
      <select v-model="input.year"
              class="py-1.5 px-2 bg-transparent border-b-2 text-black border-gray-600 text-center">
        <option disabled value="" selected>{{ $t("input_date.year") }}</option>
        <option v-for="(year, index) in years" v-bind:key="year"
                :value="index + 1">{{ $t(year) }}
        </option>
      </select>
      <!--      <input type="number" v-model="input.year" :placeholder="$t('input_date.year')"-->
      <!--             class="py-1.5 px-0 w-full border-b-2 text-black border-gray-600 focus:outline-none text-center"-->
      <!--             minlength="4" maxlength="4"/>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "InputDateComponent",
  props: {
    value: String,
  },
  emits: ['update:value'],
  data() {
    return {
      days: 31,
      months: [
        'month_list.january',
        'month_list.february',
        'month_list.march',
        'month_list.april',
        'month_list.may',
        'month_list.june',
        'month_list.july',
        'month_list.august',
        'month_list.september',
        'month_list.october',
        'month_list.november',
        'month_list.december'
      ],
      years: this.generateYears(),
      input: {
        day: "",
        month: "",
        year: ""
      }
    }
  },
  methods: {
    updateDays(e) {
      const value = e.target.value;
      if (value % 2 === 0) {
        this.days = value === 2 ? 29 : value === 2 ? 31 : 30
      } else {
        this.days = 31
      }
    },
    generateYears() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = currentYear - 80; i <= currentYear - 20; i++) {
        years.push(i.toString());
      }
      return years;
    },
  },
  updated() {
    // this.$emit('update:value', this.input.day !== "" && this.input.month !== "" && this.input.year !== "" && this.input.year.toString().length === 4 && this.input.year > 1933 && this.input.year < new Date().getFullYear() - 20 ? this.input.year + "-" + this.input.month + "-" + this.input.day : "");
    this.$emit('update:value', this.input.day !== "" && this.input.month !== "" && this.input.year !== "" ? this.input.year + "-" + this.input.month + "-" + this.input.day : "");
  }
}
</script>

<style scoped></style>
  